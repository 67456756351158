import api_core from '../../services/config';
import Operacion from './Operacion';

class Servidores extends Operacion {

    constructor() {
        super();
    }

    /**
     * Reinciamos una instancia
     * @returns {Promise<void>}
     */
    async rebootServer (server_uuid) {
        const url = `panel/servidores/reboot/${ server_uuid }`;
        return await  this.transaction('POST',url, null , true );
    }

    /**
     * Encendido de un servidor apagado
     * @param server_uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async poweOnServer (server_uuid) {
        const url = `panel/servidores/poweron/${ server_uuid }`;
        return await  this.transaction('POST',url, null , true );
    }

    /**
     * Reinciamos una instancia de manera forzada
     * @returns {Promise<void>}
     */
    async hardRebootServer (server_uuid) {
        const url = `panel/servidores/hardreboot/${ server_uuid }`;
        return await  this.transaction('POST',url, null , true );
    }

    /**
     * Archivar un servidor
     * @param server_uuid
     * @returns {Promise<any|{response: {text: *}, status: string}|undefined>}
     */
    async archiveServer (server_uuid) {
        const url = `panel/servidores/archive/${ server_uuid }`;
        return await  this.transaction('POST',url, null , true );
    }

    /**
     * Desarchivar un servidor
     * @param server_uuid
     * @returns {Promise<any|{response: {text: *}, status: string}|undefined>}
     */
    async unarchiveServer (server_uuid) {
        const url = `panel/servidores/unarchive/${ server_uuid }`;
        return await  this.transaction('POST',url, null , true );
    }

    /**
     * obtenemos la url de la consola de emergencia de una instancia
     * @returns {Promise<void>}
     */
    async consoleServer (server_uuid) {
        const url = `panel/servidores/novnc/${ server_uuid }`;
        return await  this.transaction('POST',url, null , true );
    }

    /**
     * Obtenemos detalles de una sola instancia
     * @returns {Promise<void>}
     */
    async detailServer (server_uuid) {
        const url = `panel/servidores/${ server_uuid }`;
        return await  this.transaction('GET',url, null , true );
    }

    /**
     * Instrucción para actualizar el listado de servidores
     * @returns {Promise<void>}
     */
    async conciliarServer () {
        const url = `panel/servidores/conciliar`;
        return await  this.transaction('POST',url, null , true );
    }

    async updateSucursalServer (server_uuid = '', data = {}) {
        const url = `panel/servidores/${ server_uuid }`;
        return await  this.transaction('PUT',url, JSON.stringify(data) , true );
    }

    /**
     * Descarga un listado paginado de instancias, basado en un filtro
     * @param filter
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async listado(filter = {}) {
        let url = `panel/servidores?`;
        Object.keys(filter).forEach(field =>{
            url += `${field}=${filter[field]}&`
        })
        return await  this.transaction('GET',url, null , true );
    }

    async exportarListado(filter = {}) {
        let url = `panel/servidores?`;
        Object.keys(filter).forEach(field =>{
            url += `${field}=${filter[field]}&`
        })
        return await  this.transaction('GET',url, null , true, [{option:"Accept", value:"application/vnd.ms-excel"}] );
    }

    /**
     * Obtiene todos los usuarios con acceso a determinada instancia
     * @param server_uuid
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async adminsOnServer(server_uuid  = '') {
        const url = `panel/servidores/admins/${server_uuid}`;
        return await  this.transaction('GET',url, null , true );
    }
}

export default Servidores;




