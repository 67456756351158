import { useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Permiso from "../Repository/Permiso";

export const usePermisosUsuario = () => {

    const { user } = useSelector(state => state.auth );
    const [permisos ={}, setPermisos] = useState({});

    useEffect(() => {
        const privilegios = new Permiso(user);
        setPermisos( privilegios )
    }, []);

    return {
        permisos
    }

}