import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import {useDispatch, useSelector} from "react-redux";
import LoaderAlpha from "../../dashboard/components/LoaderAlpha";
import Grid from "@mui/material/Grid";
import * as React from "react";
import Servidores from "../../../Repository/Banca/Servidores";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";
import Usuario from "./Usuario";
import {v4} from "uuid";
import NewUsuario from "./NewUsuario";
import TestComponent from "./TestComponent";
import Sucursal from "../../../Repository/Sucursal";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";
import {usePermisosUsuario} from "../../../customHooks/usePermisosUsuario";
import {useAlphaConfirmModal} from "../../../customHooks/useAlphaConfirmModal";
const Instancia = ({ server = {} }) => {

    const dispatch = useDispatch();
    const { permisos = {} } = usePermisosUsuario();
    const { setOpenAlert,alphaAlert,setMessageAlert,openAlert } = useAlphaAlertModal();
    const { setOpenConfirm,alphaConfirm,setMessageConfirm,openConfirm, statusConfirm, displayConfirm } = useAlphaConfirmModal();
    const srvInstance = new Servidores();
    const sucursalInstance = new Sucursal();
    const [usersInfo, setUsersInfo] = useState([]);
    const [serverInfo, setServerInfo] = useState(server);
    const [sucursales, setSucursales] = useState([]);
    const [sucursalUuid, setSucursalUuid] = useState(server.sucursal_uuid ? server.sucursal_uuid:"" );

    /**
     * Descargamos usuarios con acceso a este servidor
     */
    useEffect( ()=>{
        if( permisos.perfil === "ADMIN")
        {
            handleUserCreated().then(()=>{/* algo*/});
        }
        if(permisos.actualizaSucursal)
        {
            loadSucursales().then(()=>{})
        }
    },[permisos]);

    const handleUserCreated = async()=>{
        const { status, response = [] } = await srvInstance.adminsOnServer(server.uuid);
        if(status ==='ok')
        {
            setUsersInfo( response );
        }
    }

    const loadSucursales = async () =>{
        const {status, response} = await sucursalInstance.listado();
        if(status ==='ok')
        {
            setSucursales( response );
        }
    }

    /**
     *
     */
    const onClickReboot = () => {
        displayConfirm("¿Desea reiniciar el servidor?", callRebootFunc);
    }

    async function callRebootFunc()
    {
        setOpenConfirm(false);
        const {status, response} = await srvInstance.rebootServer(server.uuid);
        setMessageAlert(response);
        setOpenAlert(true);
    }

    const onClickHardReboot = () => {
        displayConfirm("¿Desea reiniciar de manera forzada el servidor?", callHardRebootFunc);
    }

    async function callHardRebootFunc ()
    {
        setOpenConfirm(false);
        const {status, response} = await srvInstance.hardRebootServer(server.uuid);
        setMessageAlert(response);
        setOpenAlert(true);
    }

    function onClickPowerOn (){
        displayConfirm("¿Desea iniciar el servidor?", callPowerOnFunc);
    }

    async function callPowerOnFunc()
    {
        setOpenConfirm(false);
        const {status, response} = await srvInstance.poweOnServer(server.uuid);
        setMessageAlert(response);
        setOpenAlert(true);
    }

    function onClickArchive (){
        displayConfirm("¿Desea archivar el servidor?", callArchiveFunc);
    }

    async function  callArchiveFunc ()
    {
        setOpenConfirm(false);
        dispatch( openGeneralLoader() )
        const {status, response} = await srvInstance.archiveServer(server.uuid);
        setMessageAlert(response);
        setOpenAlert(true);
        if(status === "ok")
        {
            await onRefreshClicked();
        }
        dispatch(closeGeneralLoader());
    }

    function onClickUnarchive (){
        displayConfirm("¿Desea desarchivar el servidor?", callUnarchiveFunc);
    }

    async function callUnarchiveFunc()
    {
        setOpenConfirm(false);
        dispatch( openGeneralLoader() )
        const {status, response} = await srvInstance.unarchiveServer(server.uuid);
        setMessageAlert(response);
        setOpenAlert(true);
        if(status === "ok")
        {
            await onRefreshClicked();
        }
        dispatch(closeGeneralLoader());
    }

    async function onRefreshClicked () {
        const {status, response} = await srvInstance.detailServer(server.uuid);
        if(status === 'error')
        {
            setMessageAlert(response);
            setOpenAlert(true);
            return;
        }
        setServerInfo( response );
    }

    function onClickGetConsole () {
        window.open(`/viewer/${ server.uuid }`, "_blank", "noreferrer")
        //window.open(`/dashboard`, "_blank", "noreferrer");
        /*
        const {status, response} = await srvInstance.consoleServer(server.uuid);
        if (status === "ok") {
            window.open(response, "_blank", "noreferrer")
        }else{
            setMessageAlert(response);
            setOpenAlert(true);
        }
        */
    }

    const handleChangeValueSucursal = async(event) =>{
        let newValue = event.target.value;
        if(newValue === ""){ newValue = null; }
        const sucursalItem = sucursales.find(element => element.uuid === newValue);
        const title = sucursalItem !== undefined ? sucursalItem.sucursal : "Sin asignación";
        const confirmado = confirm(`¿Desea continuar con la asignación a "${ title }"?`);
        if(confirmado)
        {
            dispatch( openGeneralLoader() )
            const {status, response} = await srvInstance.updateSucursalServer(server.uuid, {sucursal_uuid:newValue});
            setMessageAlert(response);
            setOpenAlert(true);
            setSucursalUuid( newValue );
            dispatch( closeGeneralLoader() )
        }else{
            setSucursalUuid( sucursalUuid );
        }
    }

    const sucursalesItem = sucursales.map(element=> {
        return <option key={ v4() } value={ element.uuid } >{ element.sucursal }</option>;
    })

    const isArchivable = serverInfo.status === 'Active' && permisos.archivar;
    const isDesarchivable = serverInfo.status === 'Archived' && permisos.desarchivar;
    const isEncender = serverInfo.status === 'Stopped' && permisos.encender;
    const isReiniciar = serverInfo.status === 'Active' && permisos.reiniciar;
    const isReiniciarForzado = serverInfo.status === 'Active' && permisos.reinicioForzado;
    const isConsolaEmergencia = serverInfo.status === 'Active' && permisos.consolaEmergencia;

    return (
        <>

            <SoftBox bgColor={"white"} mt={2}>
                <div className="accordion">
                    <div className="accordion-header">
                        <i className={" icons-alpha"}></i> <label
                        className={"filtro-text"}>{"Información del Servidor"}</label>

                    </div>
                    <SoftBox sx={{border: "5px solid #E6E6E6", paddingLeft: 1, paddingRight: 1, marginTop: 1}}>
                        <Grid container>
                            <Grid item xs={12} md={3} xl={2} mt={2}>
                                <button className={"btn-alpha-success-2"} type={"submit"} onClick={ onRefreshClicked }>
                                    <i className="fa-solid fa-arrow-rotate-right"></i> Refrescar
                                </button>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={2} xl={2} pl={1}>
                                <span className={"lbl-etiqueta"}>Nombre</span>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3}>
                                <input type={"text"} className={"ITextbox"} name={"name"} value={serverInfo.name} readOnly/>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3} pl={1}>
                                <span className={"lbl-etiqueta"}>IP pública</span>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3}>
                                <input type={"text"} className={"ITextbox"} name={"publicIp"} value={serverInfo.publicIp}
                                       readOnly/>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={2} xl={2} pl={1}>
                                <span className={"lbl-etiqueta"}>Hosname</span>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3}>
                                <input type={"text"} className={"ITextbox"} name={"name"} value={serverInfo.hostname}
                                       readOnly/>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3} pl={1}>
                                <span className={"lbl-etiqueta"}>Estatus</span>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3}>
                                <input type={"text"} className={"ITextbox"} name={"publicIp"} value={serverInfo.status}
                                       readOnly/>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={2} xl={2} pl={1}>
                                <span className={"lbl-etiqueta"}>Memoria RAM (GB) </span>
                            </Grid>
                            <Grid item xs={12} md={1} xl={1}>
                                <input type={"text"} className={"ITextbox"} name={"name"} value={serverInfo.ramGb}
                                       readOnly/>
                            </Grid>
                            <Grid item xs={12} md={2} xl={2} pl={1}>
                                <span className={"lbl-etiqueta"}>CPU vCores</span>
                            </Grid>
                            <Grid item xs={12} md={1} xl={1}>
                                <input type={"text"} className={"ITextbox"} name={"publicIp"} value={serverInfo.vCores}
                                       readOnly/>
                            </Grid>
                            <Grid item xs={12} md={2} xl={2} pl={1}>
                                <span className={"lbl-etiqueta"}>Disco duro (GB)</span>
                            </Grid>
                            <Grid item xs={12} md={1} xl={1}>
                                <input type={"text"} className={"ITextbox"} name={"publicIp"}
                                       value={serverInfo.volumeSizeGb} readOnly/>
                            </Grid>
                        </Grid>
                        {
                            permisos.actualizaSucursal &&
                            <Grid container pt={0.5}>
                                <Grid item xs={12} md={2} xl={2} pl={1}>
                                    <span className={"lbl-etiqueta"}>Sucursal </span>
                                </Grid>
                                <Grid item xs={12} md={3} xl={3}>
                                    <select className={"ITextbox"} id={"sucursal_uuid"} name={"sucursal_uuid"} value={ sucursalUuid} onChange={ handleChangeValueSucursal }>
                                        <option key={ v4()} value={""}>No asignada</option>
                                        {
                                            sucursalesItem
                                        }
                                    </select>
                                </Grid>
                            </Grid>
                        }

                        <Grid container>
                            {
                                isEncender &&
                                <>
                                    <Grid item xs={12} md={3} xl={2} mt={2}>
                                        <button className={"btn-alpha-info-1"} type={"submit"} onClick={onClickPowerOn}>
                                            <i className="fa-solid fa-power-off"></i> Encender
                                        </button>
                                    </Grid>
                                </>
                            }
                            {
                                isReiniciar &&
                                <>
                                    <Grid item xs={12} md={3} xl={2} mt={2}>
                                        <button className={"btn-alpha-info-1"} type={"submit"} onClick={onClickReboot}>
                                            <i className="fa-solid fa-power-off"></i>Reiniciar
                                        </button>
                                    </Grid>
                                </>
                            }
                            {
                                isReiniciarForzado &&
                                <>
                                    <Grid item xs={12} md={3} xl={2} mt={2}>
                                        <button className={"btn-alpha-info-3"} type={"submit"} onClick={onClickHardReboot}>
                                            <i className="fa-solid fa-hand-point-up"></i>Reinicio Forzado
                                        </button>
                                    </Grid>
                                </>
                            }
                            {
                                isConsolaEmergencia &&
                                <>
                                    <Grid item xs={12} md={4} xl={3} mt={2}>
                                        <button className={"btn-alpha-danger"} type={"submit"} onClick={onClickGetConsole}>
                                            <i className="fa-solid fa-terminal"></i>Consola de Emergencia
                                        </button>
                                    </Grid>
                                </>
                            }
                            {
                                 isArchivable  && <>
                                    <Grid item xs={12} md={4} xl={2} mt={2}>
                                        <button className={"btn-alpha-gray-1"} type={"submit"} onClick={onClickArchive}>
                                            <i className="fa-solid fa-box-archive"></i>Archivar
                                        </button>
                                    </Grid>
                                </>
                            }
                            {
                                isDesarchivable  && <>
                                    <Grid item xs={12} md={4} xl={2} mt={2}>
                                        <button className={"btn-alpha-info-3"} type={"submit"} onClick={onClickUnarchive}>
                                            <i className="fa-regular fa-folder-open"></i>Desarchivar
                                        </button>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </SoftBox>
                    {
                        permisos.perfil === "ADMIN" &&
                        <NewUsuario server={serverInfo} handleUserCreated={handleUserCreated}/>
                    }
                    {
                        usersInfo.map(usr => <Usuario key={v4()} server={serverInfo} userInfo={usr}/>)
                    }

                </div>
            </SoftBox>
            {
                openAlert && alphaAlert
            }
            {
                openConfirm && alphaConfirm
            }
            <LoaderAlpha/>

        </>
    );
}
Instancia.propTypes = {
    server: PropTypes.object,
};
export default Instancia;