import moment from "moment";
import {JavascriptOutlined} from "@mui/icons-material";
class Operacion {


    request = (method ,url, body, security = false )=>{

        let requestOptions = {
            method,
        }
        if(security){
            requestOptions.headers = {
                'Authorization': `Bearer ${localStorage.getItem('cToken')}`
            }
        }
        if(body){
            //requestOptions.body = JSON.stringify(body) 
            requestOptions.body = body 
        }

        return new Promise((resolve, fallback)=>{
            fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => {
                    if(response.response.hasOwnProperty(`refreshToken`)){
                        localStorage.setItem('cToken', (response.response.token) );   
                    }
                    resolve(response);
                })
                ;
        })
    }

    //hOptions = [{option:"Accept", value:"application/json"}]
    async transaction (method ,url, body, security = false, hOptions = [] ){
        try{

            let requestOptions = {
                method,
            }

            requestOptions.headers = {
                'Accept':"application/json",'Content-Type': 'application/json'
            }
            hOptions.forEach(function (opth){
                requestOptions.headers[opth.option] = opth.value;
            });
            if(security){
                requestOptions.headers['Authorization'] = `Bearer ${localStorage.getItem('cToken')}`;
            }

            if(body){
                requestOptions.body = body
            }
            let currentRequest = await fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions).then(response => (response))
            if( requestOptions.headers['Accept'] === "application/vnd.ms-excel")
            {
                console.log(requestOptions.headers, "inside");
                const extension = "xlsx";
                const date = new Date();
                const reporte = `listado de servidores ${date.getFullYear()}${date.getMonth() + 1}${date.getDate()} ${date.getHours()}.${extension}`;

                const blob = await currentRequest.blob();
                console.log("algo", blob);

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', reporte);

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                console.log("es un excels");
            }else{
                return currentRequest. json();
            }
        }catch(error){
            //console.log(error, "::error");
            return {
                status : 'error',
                response: {
                    text: error
                }
            };
        }
    }

    async blobTransaction (method ,url, body, security = false, headers = {} ){

        try{
            const isvalid = await this.isValidToken();
            let requestOptions = {
                method,
            }
            if(security){
                requestOptions.headers = {
                    'Content-Type': 'application/json','Authorization': `Bearer ${localStorage.getItem('cToken')}`
                }
            }
            if(headers['Accept']){
                requestOptions.headers['Accept'] = headers['Accept'];
            }
            if(body){
                requestOptions.body = body
            }
            //console.log(1);
            let currentRequest = await fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions).then(response => (response))
            return currentRequest;
        }catch(error){
            return {
                status : 'error',
                response: {
                    text: error
                }
            };
        }
    }

    async execute(isvalid, url,options, original, headers){
        if(isvalid){
            return original;
        }
        options.headers = headers;

        const currentRequest = await fetch(`${process.env.REACT_APP_API_CORE}${url}`, options).then(response => (response))
        return currentRequest;
    }

    isValidRequest ( currentRequestuest ){
        if(currentRequestuest.status == 401 ){
            return false;
        } 
        return true;
    }

    async refreshToken (isvalid){
        if (isvalid){
            return true;
        }

        const requestOptions = {
            'method': 'POST',
            headers : {
                'Authorization': `Bearer ${localStorage.getItem('cRefreshToken')}`
            }
        }
        const url = `panel/refreshtoken`;
         await fetch(`${process.env.REACT_APP_API_CORE}${url}`, requestOptions)
                .then((response) =>{
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                }).then((response) => response.json())
                .then((response) => {
                    const userSession = JSON.parse( localStorage.getItem("session") );
                    const newUser =  {
                        ...userSession,
                        expires: response.response.expires
                    }
                    localStorage.setItem('cToken', (response.response.token));
                    localStorage.setItem('session',  JSON.stringify(newUser) );
                })
                ;
    }

    isValidToken = async ()=>{
        return new Promise((resolve)=>{
            moment.locale('es-MX');
            const userSession = JSON.parse( localStorage.getItem("session") );
            const expires = moment( userSession.expires);
            const now = moment();
            if( expires.isValid() ){
                const diferencia = expires.diff( now, "minutes");
                if(diferencia > 0){
                    resolve({ status: true });
                }
            }
            resolve({ status: false });
        })

    }

   

}

export default Operacion;