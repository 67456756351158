/**
=========================================================
* Acceso a la consola de clouding
==================================================================================================================
*
*/
// @mui material components
import {useDispatch, useSelector} from "react-redux";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Servidores from "../../Repository/Banca/Servidores";
function Clouding() {

    const dispatch = useDispatch();
    const { windowHeight } = useSelector( state => state.ventana );
    const {  roles = [], user  } = useSelector(state => state.auth);
    const [tokenX,setTokenX] = useState(undefined);
    const { uuid: server_uuid } = useParams();
    const srvInstance = new Servidores();

    useEffect(() => {
        const tmpFunc = async()=>{
            const {status, response} = await srvInstance.consoleServer(server_uuid);
            if(status === 'ok')
            {
                setTokenX(`https://cloud.castelec.mx/console/vnc_auto.html?token=${response}`);
                document.cookie = `token=${ response }`;
            }
            else{
                //TODO::cerrar el tab por que esta no es una operación valida
            }
        }
        tmpFunc().then(()=>{});

    }, []);

    return (
      <DashboardLayout sx={{ overflow:"hidden" }} >
        <DashboardNavbar light={true} />
        <SoftBox sx={{ marginTop:1, textAlign:"center" }}>
            {
                tokenX !== undefined &&
                <iframe src={`${tokenX}`} width={"100%"}
                        height={"850"}>
                </iframe>
            }
        </SoftBox>
      </DashboardLayout>
    );
}

export default Clouding;
