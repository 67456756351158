import api_core from '../../services/config';
import Operacion from './Operacion';
class Admin extends Operacion{

    constructor() {
        super();
    }

    //Crea una persona, sin asignarle ningun tipo de permiso
    static store(data = {}){
        return new Promise((resolve)=>{
            let url = `/panel/admins`;
            let bodyFormData = new FormData();
            bodyFormData.append(`nombreCompleto`, `${data.nombreCompleto}`);
            bodyFormData.append(`correo`, `${data.correo}`);
            bodyFormData.append(`server_uuid`, `${data.server_uuid}`);
            bodyFormData.append(`password`, `${data.password}`);
            api_core.post(`${url}`,bodyFormData ,{
                    headers: 
                    { 
                        Authorization: `Bearer ${localStorage.getItem('cToken')}`
                    }
                })
            .then(res => {
                let data = res.data;
                resolve(data);
            })
        });
    }

    async mostrar(uuid = ''){
        let url = `panel/persona/${uuid}/mostrar`;
        return await this.transaction('GET',url, null , true );
    }

    //Crea una persona, sin asignarle ningun tipo de permiso
    async listar(data){
        let uri = `nombres=${data.nombres}`;
        uri +=  data.soloActivos ? '&soloActivos='+data.soloActivos:'';
        uri +=  data.cuenta_uuid ? '&cuenta_uuid='+data.cuenta_uuid:'';
        const url = `panel/persona/listar?${ uri }`;
        return await this.transaction('GET',url, null , true );
    }

    async cambiarEstado(uuid, value){
        const url = `panel/persona/${uuid}/estatus/${value}`;
        return await this.transaction('DELETE',url, null , true );
    }

    async update(data, uuid){
        let url = `panel/admins/${ uuid }`;
        return await this.transaction('PUT',url, JSON.stringify(data) , true );
    }

    async updateNotificacionesPeriodicas(data, uuid){
        let url = `panel/persona/${uuid}/actualizar/notificaciones`;
        return  await this.transaction('POST',url, JSON.stringify(data) , true );
    }

    static buscar(data){
        return new Promise((resolve)=>{
            let params = `${localStorage.getItem('cToken')}`;
            let url = `${process.env.REACT_APP_API_CORE}panel/persona/buscar?nombres=${data}`;
            var xhr = new XMLHttpRequest();
            xhr.open(`GET`,`${url}`,true);
            xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhr.setRequestHeader('Authorization',  `Bearer ${localStorage.getItem('cToken')}`);
            xhr.send({});
            xhr.onload=function(event){
                resolve((JSON.parse(xhr.responseText)));
            };
        });
    }

    /**
     * Obtiene todas las instancias donde el usuario en sesión tiene acceso
     * @returns {Promise<*|{response: {text: *}, status: string}|undefined>}
     */
    async serversAssigned(){
        const url = `panel/admins/servers`;
        return  await this.transaction('GET',url, null , true );
    }

    static CdcDePersona(cuenta_uuid,persona_uuid){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/cdcsasociadas`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({'cuenta_uuid': cuenta_uuid, 'persona_uuid':persona_uuid}) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
        
    }

    static activate2Fa(persona_uuid){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/otp/${persona_uuid}/activar`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    static delete2Fa(persona_uuid){
        return new Promise((resolve, fallback)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/otp/${persona_uuid}/desactivar`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    static getOtpQrcode(token_temporal){
        return new Promise((resolve, fallback)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/otp/${token_temporal}/qrcode`;
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` }
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    //console.log('...', response);
                    if(response.status == 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) =>{
                    console.log(response);
                    return response.json()
                } )
                .then((response) => resolve(response))
                ;
        });
    }


    static confirmar2Fa(persona){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/confirma-otp`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(persona) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
        
    }

    static notificarPorCorreoOTP(persona_uuid){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/otp/${persona_uuid}/enviarcorreo`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    async enviarCorreoInicial (persona_uuid){
        const url = `panel/persona/${persona_uuid}/correoinicial`;
        return  await this.transaction('POST',url, JSON.stringify({})  , true );
    }

    async enviarRenovarContrasenia(persona_uuid){
        const url = `panel/persona/${persona_uuid}/renovarcontrasenia`;
        return await this.transaction('POST',url, JSON.stringify({})  , true );
    }

    static desactivarOTP(persona_uuid, otpCode ){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/otp/${persona_uuid}/desactivar`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify({'password':otpCode}) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
    }

    
    static resetPassword(data){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/resetpassword`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: JSON.stringify(data) 
            };

            fetch(url, requestOptions)
                .then((response) =>{
                    if(response.status === 401){
                        localStorage.clear();
                        window.location.reload(false);
                    }else{
                        return response;
                    }
                })
                .then((response) => response.json())
                .then((response) => resolve(response))
                ;
        });
        
    }
    

    async resetPasswordAutorizador(data){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/resetpasswordautorizador`;
            const requestOptions = {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => resolve(response))
            ;
        });
    }

   

    static subirFoto(data){
        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/subirfoto`;
            const requestOptions = {
                method: 'POST',
                headers: {  'Authorization': `Bearer ${localStorage.getItem('cToken')}` },
                body: data 
            };
            
            fetch(url, requestOptions)
            .then((response) =>{
                if(response.status === 401){
                    localStorage.clear();
                    window.location.reload(false);
                }else{
                    return response;
                }
            })
            .then((response) => response.json())
            .then((response) => resolve(response))
            ;
        });
        
    }

    async bajarFoto(){
        const url = `panel/persona/bajarfoto`;
        return await this.transaction('POST',url, JSON.stringify({}) , true );
    }

    async getPersonaPorToken(token_temporal){

        return new Promise((resolve)=>{
            const url = `${process.env.REACT_APP_API_CORE}panel/persona/otp/${token_temporal}/qrcode`;
            const requestOptions = {
                method: 'GET'
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => resolve(response))
            ;
        });
    }

    async loggin(credentials){
        const url = `panel/login`;
        return await this.transaction('POST',url, JSON.stringify(credentials)  , false );
        //return await api_core.post(`${url}`, JSON.stringify(credentials) );
    }
}
export default Admin;