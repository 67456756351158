/**
 * Listado de servidores
 */
import {useEffect, useState} from "react";
import SoftBox from "../../../components/SoftBox";
import servidores from "../../../Repository/Banca/Servidores";
import {v4} from "uuid";
import Grid from "@mui/material/Grid";
import AlphaTable from "../../cuentas/components/AlphaTable";
import Paginador from "./Paginador";
import {useDispatch} from "react-redux";
import Filtro from "../../cuentas/components/Filtro";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import * as React from "react";
import {useDateFormat} from "../../../customHooks/useDateFormat";
import Instancia from "./Instancia";
import WebModal from "../../cuentas/components/WebModal";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";
import Box from "@mui/material/Box";
import {usePermisosUsuario} from "../../../customHooks/usePermisosUsuario";


const defaultFiltro = {
    fechaaltadespuesde: "",
    fechaaltaantesde:"",
    textoBuscar:""
}
const Listado = ( props ) => {
    const dispatch = useDispatch();
    const { setOpenAlert,alphaAlert,setMessageAlert,openAlert } = useAlphaAlertModal();
    const { permisos = {} } = usePermisosUsuario();
    const { localeFormat } = useDateFormat();
    const srvInstance = new servidores();

    const [ fullData, setFullData] = useState({});
    const [listadoServidores, setListadoServidores] = useState([]);
    const [serverSelected, setServerSelected] = useState(undefined);
    const [filter, setFilter] = useState(defaultFiltro);

    useEffect(() => {
        loadListado().then(()=>{ /* ok */ });
    }, []);

    const loadListado = async (filter = {}) =>{
        dispatch(openGeneralLoader() )
        const { status, response } = await srvInstance.listado(filter);
        setListadoServidores( response.data );
        setFullData( response );
        dispatch( closeGeneralLoader() )
    }

    const onClickFiltro = () => {
        loadListado(filter);
    }

    const onClickSincronizar = () => {
        const x1 = async ()=> {
            dispatch( openGeneralLoader() )
            const { status, response } = await srvInstance.conciliarServer();
            setMessageAlert(response);
            setOpenAlert(true);
            await loadListado(
                { ...defaultFiltro }
            )
            dispatch( closeGeneralLoader() );
        }
        x1();
    }

    const onClickExportar =  async() => {
        await srvInstance.exportarListado(filter);
    }

    const nextEvent = (filtro = {}) =>{
        loadListado(
                { ...defaultFiltro, ...filtro }
            )
        }

    const prevEvent = (filtro = {}) =>{
        loadListado(
            { ...defaultFiltro, ...filtro }
        )
    }

    const handleServerSelect = async (server) => {
        dispatch(openGeneralLoader());
        const {status, response} = await srvInstance.detailServer(server.uuid);
        if(status === "ok"){
            setServerSelected( response );
        }
        dispatch(closeGeneralLoader());
    }

    const handleCloseParent = () =>{
        setServerSelected( undefined );
        loadListado(filter);
    }



    const headers = [
        { name:"IP Pública", width:"4%" },
        { name:"Nombre", width:"10%" },
        { name:"Sucursal", width:"5%" },
        { name:"Zona", width:"4%" },
        { name:"Estatus", width:"3%" },
        { name:"Capacidad", width:"4%" },
        { name:"RAM", width:"3%" },
        { name:"Vcores", width:"3%" },
        { name:"Creación", width:"4%" },
    ];

    const rows = listadoServidores.map(element => {
        return (
            <tr key={v4()}>
                <td><label className={"ILabelLink"}
                           onClick={(event) => handleServerSelect(element)}>{element.publicIp}</label></td>
                <td>{element.name}</td>
                <td>{element.sucursal}</td>
                <td><Box sx={{ textAlign:"center" }}>{element.zona}</Box></td>
                <td><Box sx={{ textAlign:"center" }}>{element.status}</Box></td>
                <td><Box sx={{ textAlign:"center" }}>{element.volumeSizeGb}</Box></td>
                <td><Box sx={{ textAlign:"center" }}>{element.ramGb}</Box></td>
                <td><Box sx={{ textAlign:"center" }}>{element.vCores}</Box></td>
                <td>{element.createdAt}</td>
            </tr>
        );
    })

    const formContent = <Grid container  p={1}>
        <Grid item xs={12} md={3} xl={3} pr={1}>
            <input type={"text"} className={"ITextbox"} placeholder={"Buscar"} name={"textoBuscar"} value={ filter.textoBuscar }
                   onChange={
                       event => setFilter((filterOld) => {
                           return {...filterOld, textoBuscar:event.target.value };
                       })
                   }
            />
        </Grid>
        <Grid item xs={12} md={2} xl={2} pr={1}>
            <Flatpickr
                value={ filter.fechaaltadespuesde }
                options ={{ dateFormat:"d-m-Y", altFormat:"d-m-Y", locale:  localeFormat    }}
                className={"ITextbox"}
                placeholder={"dd-mm-aaaa"}
                onChange={(selectedDates, dateStr, instance) => {
                    setFilter((filterOld) => {
                        return {...filterOld, fechaaltadespuesde:dateStr };
                    })
                }}
            />
        </Grid>
        <Grid item xs={12} md={2} xl={2} pr={1}>
            <Flatpickr
                value={ filter.fechaaltaantesde}
                options ={{ dateFormat:"d-m-Y", altFormat:"d-m-Y", locale:  localeFormat    }}
                className={"ITextbox"}
                placeholder={"dd-mm-aaaa"}
                onChange={(selectedDates, dateStr, instance) => {
                    setFilter((filterOld) => {
                        return {...filterOld, fechaaltaantesde:dateStr };
                    })
                }}
            />
        </Grid>
        <Grid item xs={12} md={2} xl={2} pl={1} pr={1} >
            <button className={"btn-alpha-black pad-element"} type={"submit"} onClick={ onClickFiltro }>
                <i className="fa-solid fa-arrows-rotate"></i> Traer Datos
            </button>
        </Grid>
        {
            permisos.actualizaListadoServidores &&
            <>
                <Grid item xs={12} md={3} xl={2} pl={1} pr={1}>
                    <button className={"btn-alpha-info-3 pad-element"} type={"submit"} onClick={onClickSincronizar}>
                        <i className="fa-solid fa-network-wired"></i> Sincronizar
                    </button>
                </Grid>
            </>
        }
        {
            permisos.exportaListadoServidores &&
            <>
                <Grid item xs={12} md={2} xl={1} pl={1} pr={1}>
                    <button className={"btn-alpha-success-2 pad-element"} type={"submit"} onClick={onClickExportar}>
                        <i className="fa-solid fa-file-excel"></i> Exportar
                    </button>
                </Grid>
            </>
        }
    </Grid>;

    return (
        <>
            <SoftBox bgColor={"white"} sx={{paddingTop: 1}}>
                <Filtro bodyContent={formContent} open={true} title={"Servidores"} icon={""}/>
            </SoftBox>
            <SoftBox bgColor={"white"}>
                <Grid container>
                    <Grid item xs={12}>
                        <AlphaTable records={ rows } headers={ headers } />
                    </Grid>
                </Grid>
                <Grid container justifyContent={"center"} direction={"row"} alignItems={"center"}>
                    <Grid item xs={8} sm={8} md={5} xl={4} lg={4}>
                        <SoftBox sx={{ width:"100%" }}>
                            <Paginador cursor={ fullData.cursor } nextEvent={ nextEvent } prevEvent={ prevEvent } />
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>
            {
                serverSelected !== undefined && <WebModal title={"Información"} modalSize={"xl"} handleCloseParent={ handleCloseParent }
                                                          formComponent={ <Instancia server={ serverSelected } /> }  />
            }
            {
                openAlert && alphaAlert
            }
        </>
    );
}
export default Listado;