import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Admin from "../../../Repository/Banca/Admin";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";
import * as React from "react";
import Instancia from "./Instancia";
import {v4} from "uuid";
const Instancias = (props) => {

    const dispatch = useDispatch();
    const { user} = useSelector(state => state.auth);
    const [servidores, setServidores] = useState([]);
    const iconDown = <i className="fa-solid fa-chevron-down icons-alpha"></i>;
    const iconUp = <i className="fa-solid fa-angle-up icons-alpha"></i>;
    const admin = new Admin();

    useEffect( ()=>{ initServerInfo() },[])

    /**
     * Methods
     */
    const initServerInfo = async () => {
        dispatch( openGeneralLoader() );
        const result = await admin.serversAssigned();
        dispatch( closeGeneralLoader() );
        setServidores( result.response );
    }

    return (
        <>
            {
                servidores.map(element => {
                    return <Instancia key={ v4() } server={ element } />
                })
            }
        </>

    );
}

export default Instancias;