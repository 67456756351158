import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/authSlice'
import counterSlice from './slice/counterSlice'
import ventanaSlice from "./slice/ventanaSlice";
import personaSlice from "./slice/personaSlice";
import alertSlice from "./slice/alertSlice";
import captchaSlice from "./slice/captchaSlice";
import PersonaAdmon from "./slice/banca/personaSlice"

export const store = configureStore({
  reducer: {
    counter: counterSlice,
    auth: authSlice,
    persona:personaSlice,
    ventana: ventanaSlice,
    alertas: alertSlice,
    catpcha: captchaSlice,
    personas_general:PersonaAdmon,
  },
})