import React, {  useEffect, useRef, useState } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import Swal from 'sweetalert2';

import Card from "@mui/material/Card";
import Admin from "../../../../Repository/Banca/Admin";
import Box from "@mui/material/Box";

function ActivateOtp({ credentials, OnSuccessConfirm }) {
    console.log(credentials);
    const [data, setData] = useState(credentials);
    const [completed, setCompleted] = useState(false);
    const {uuid} = data;
    const [otpConfirmation, setOtpConfirmation] = useState('');
    const onClickBtnConfirmar = (event)=>{
        event.preventDefault();
        procesar();
    }
    const procesar = () =>{
        if(otpConfirmation.trim().length !== 6 ){
            Swal.fire({
                title: 'Notificaciones',
                text: 'Por lo general son 6 números',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            });
            return false;
        }

        const params = {
            adminUuid:uuid,
            otpConfirmation
        }
        Admin.confirmar2Fa(params).then((response)=>{
            if(response.status === 'error'){
                Swal.fire({
                    title: 'Notificaciones',
                    text: response.response,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false
                });
            }else{
                OnSuccessConfirm();
            }
        });
    }

    return(
        <>
            <Box sx={{ width:"100%" }}>
                <SoftBox p={1} mb={1} textAlign="center">
                    <div style={{ color:"white" }}>
                        Descarga la aplicación de Google Authenticator y escanea el QR
                    </div>
                </SoftBox>
                <SoftBox mb={2} textAlign={  "center" }  >
                    <img style={{ borderRadius:10 }} className="" src={ "data:image/png;base64,"+credentials.otp.qrCode } alt="QR de confirmación" width={300}   />
                </SoftBox>

            </Box>
            <Box>
                {
                    !completed && (
                        <>
                            <SoftBox pt={2} pb={3} px={3}>
                                <SoftBox component="form" role="form" onSubmit= { onClickBtnConfirmar } >
                                    <SoftBox pb={1} mb={1} lineHeight={0} textAlign="center">
                                        <div style={{ color:"white" }}>
                                            Escriba el código de confirmación
                                        </div>
                                    </SoftBox>
                                    <SoftBox mb={2}>
                                        <input type={"otpConfirmation"} name={"otpConfirmation"} value={otpConfirmation}
                                               placeholder={"******"} className={"input-alpha"}
                                               onChange={event => setOtpConfirmation(event.target.value)}
                                               autoComplete="ne-time-code"
                                        />
                                    </SoftBox>

                                    <SoftBox mt={4} mb={1}>
                                        <button type={"submit"} className={"btn-alpha-success"}>
                                            Confirmar
                                        </button>
                                    </SoftBox>

                                </SoftBox>
                            </SoftBox>
                        </>
                    )
                }
            </Box>
        </>
    )

}

ActivateOtp.propTypes = {
    credentials: PropTypes.object,
    OnSuccessConfirm: PropTypes.func
};

export default ActivateOtp;