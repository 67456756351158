import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import SoftBadge from "../components/SoftBadge";
import closePopupImg from "../assets/alpha-theme/images/cerrarPopup.png";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {useState} from "react";

export const useAlphaConfirmModal = () => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [messageConfirm,setMessageConfirm] = useState("");
    const [sizeConfirm,setSizeConfirm] = useState("sm");//xl,md,lg,sm, xs
    const [statusConfirm,setStatusConfirm] = useState(false);
    const [callbackSuccess, setCallbackSuccess] = useState(()=>{});

    const handleCloseAlert = () => {
        setOpenConfirm(false);
    }

    function displayConfirm(actionToConfirm =  "", callbackSuccess)
    {
        setStatusConfirm(false);
        setMessageConfirm(actionToConfirm);
        setOpenConfirm(true);
        setCallbackSuccess(() => callbackSuccess);
    }

    const alphaConfirm =  <>
        <Dialog open={ true } onClose={handleCloseAlert} fullWidth={true}  maxWidth={ sizeConfirm }
                PaperProps={{ style:{ backgroundColor:"#3d3d3d", borderRadius:"0.6rem", color:"white", overflowY:"unset" } }}
        >

            <DialogTitle >
                <div className={"IAlertTitle"} >
                    { "Notificaciones" }
                </div>
                <SoftBadge
                    onClick={handleCloseAlert}
                    sx={{
                        position: 'absolute',
                        right: -13,
                        top: -16,
                        color: "#fff",
                        backgroundColor: "#DB6161",
                        fontSize:"0.8rem",
                        border:"3px solid white",
                        borderRadius: "50%",
                        padding:"3px",
                        cursor:"pointer",
                    }}
                >
                    <img src={ closePopupImg } alt={"Img Cerrar PopUp"} width={ "20px" } height={"20px"}  />
                </SoftBadge>

            </DialogTitle>
            <DialogContent>

                <Grid container  >
                    <div className={"IAlertMessage"}>
                        { messageConfirm }
                    </div>
                </Grid>
                <Grid container justifyContent={"right"} alignItems={"flex-end"} paddingTop={1}>
                    <Grid item xs={12} md={4} xl={4}  >
                        <button className={"IBtnAlertDanger"} type={"submit"} onClick={ handleCloseAlert }>
                            <i className="fa-solid fa-xmark"></i> <span>Cancelar</span>
                        </button>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}  >
                        <button className={"IBtnAlertSuccess"} type={"submit"} onClick={ callbackSuccess }>
                            <i className="fa-solid fa-circle-check"></i> <span>Aceptar</span>
                        </button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </>

    return {
        alphaConfirm, openConfirm, setOpenConfirm, setMessageConfirm, setSizeConfirm, statusConfirm, setStatusConfirm,displayConfirm
    }

}