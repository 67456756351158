/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/alphaerp.png";
import api_core from '../../../services/config.js';
import {useDispatch, useSelector} from "react-redux";
import {doLogin} from "../../../store/thunk/auth";
import Admin from "../../../Repository/Banca/Admin";
import * as React from "react";
import ConfirmOtp from "./Components/ConfirmOtp";
import ActivateOtp from "./Components/ActivateOtp";

const initialStateCredentials = {
    sesion:{},
    token: '',
    correo : '',
    nombre: '',
    expires_in:'',
    expires:'',
    uuid:'',
    password:'',
    otp:{
        activarOtp:false,
        qrCode:null,
    },
    actions:{},
}

function SignIn() {
  const [errormessage, setErrorMessage] = useState('');
  const [activarOtp, setActivarOtp] = useState(false);
  const [ingresarOtp, setIngresarOtp] = useState(false);
  const [credentials, setCredentials] = useState( initialStateCredentials );
  const { sesion,  token, correo ,  nombreCompleto,  expires_i,  expire,    uuid, password} = credentials;
  const adminInstance = new Admin();

  const dispatch = useDispatch();
  const {  token:tokn } = useSelector( state => state.auth );

  /* Login from de server  */
 const login  =   event => {
    event.preventDefault();
    let url = `panel/login`;

    if(credentials.password === ''){
      setErrorMessage(<label>Contraseña requerida</label>);
      return false;
    }
    setErrorMessage(()=>('') );

    adminInstance.loggin({ password :credentials.password})
        .then(res => {
            console.log(res);
            const { status, response } = res;
            if(status === 'ok'){
                setCredentials(credentials => {
                    return {
                        sesion: JSON.stringify(response),
                        expires_i: response.expires_in,
                        expire: response.expires,
                        ...response
                    }
                });
                if(!response.actions.verificarOtp) {
                    //nSuccessConfirm();
                    dispatch(doLogin(response));
                }
                if(response.actions.verificarOtp && !response.otp.qrCode){
                    setIngresarOtp(true);
                }
                if(response.actions.verificarOtp && response.otp.qrCode){
                    setActivarOtp(true);
                }
            }
        }).catch(function (error) {
            //if (error.response) {
                setErrorMessage(()=>('Acceso denegado') );
            //}
        })

 }

 const changePropiedades = ({target}) =>{
    setCredentials({
      ...credentials,
      [target.name] : target.value
    });
 }

 function OnSuccessConfirm (){
     /*
     const result =  JSON.parse(sessionStorage.getItem('tmp'));
     const {response} = result;

     sessionStorage.removeItem('tmp');
     */
     setActivarOtp(false);
     setIngresarOtp(false);
     dispatch( doLogin( credentials ) );
 }
 function  OnSuccessActivate(){
     setActivarOtp(false);
     setIngresarOtp(true);
 }

  return (
    <CoverLayout
      title={ "" }
      description=""
      image={curved9}
      >
        <SoftBox component="form" role="form" onSubmit={login} >

        {
          !credentials.uuid && (
              <>
                <SoftBox mb={2} pt={1} sx={{ textAlign:"center" }} >
          
                  <SoftBox mb={1} ml={1}>
                    <SoftTypography fontWeight="bold" sx={{ color:"#BEBEBE",fontSize:"14px" }} >
                      { errormessage }
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox mb={2}>
                    <input type={"password"} name={"password"} value={password} placeholder={"Contraseña"} className={"input-alpha"}
                           onChange={ event => changePropiedades(event) } required  autoComplete="current-password"
                    />
                </SoftBox>

                  <SoftBox mt={2} mb={1}>
                      <button type={"submit"} className={"btn-alpha-success"}>
                          Entrar
                      </button>
                  </SoftBox>
                  <SoftBox mt={4} textAlign={"right"} opacity={0.6}>
                      <img src={curved9} height={"35vh"} alt={"Logo Alpha ERP"}/>
                  </SoftBox>
              </>
          )
        }
        </SoftBox>
        {
            ingresarOtp && <ConfirmOtp credentials={credentials} OnSuccessConfirm ={ OnSuccessConfirm } />
        }
        {
            activarOtp && <ActivateOtp credentials={credentials} OnSuccessConfirm ={ OnSuccessActivate } />
        }
    </CoverLayout>
  );
}


export default SignIn;
