/**
 * Hook para crear contraseñas
 * @returns {{copyToClipboard: generatePassword: (function(number=, boolean=, boolean=, boolean=, boolean=): string)}}
 */

export const usePasswordGenerator = () => {

    /**
     *Generar una contraseña aleatoria
     * @param passwordLength
     * @param useSymbols
     * @param useNumbers
     * @param useLowerCase
     * @param useUpperCase
     * @returns {string}
     */
    const generatePassword = (passwordLength = 20, useSymbols = true,useNumbers = true, useLowerCase = true, useUpperCase = true ) => {
        let charset = "";
        let newPassword = "";
        if (useSymbols) charset += "!@#$%^&*()_'-~";
        if (useNumbers) charset += "0123456789";
        if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
        if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        for (let i = 0; i < passwordLength; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return newPassword;
    };

    return {
        generatePassword
    }

}