/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base colors for the Soft UI Dashboard React.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard React using thie file.
 */

const colors = {
  background: {
    default: "#fff",
  },

  text: {
    main: "#67748e",
    focus: "#67748e",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },


  black: {
    light: "#141414",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    main: "#cb0c9f",
    focus: "#ad0a87",
  },

  secondary: {
    main: "#ffffff",
    focus: "#96a2b8",
  },

  info: {
    main: "#17c1e8",
    focus: "#3acaeb",
  },

  success: {
    main: "#82d616",
    focus: "#95dc39",
  },

  warning: {
    main: "#fbcf33",
    focus: "#fcd652",
  },

  error: {
    main: "#ea0606",
    focus: "#c70505",
  },

  light: {
    main: "#e9ecef",
    focus: "#e9ecef",
  },

  dark: {
    main: "#344767",
    focus: "#344767",
  },

   /* colores castelec  */
   color1T: {
    main: "#59C6EA",
    focus: "#82BBD8",
  },

  color2T: {
    main: "#7EC4BC",
    focus: "#7EC4BC",
  },

  color3T: {
    main: "#84C3CE",
    focus: "#84C3CE",
  },

  color4T: {
    main: "#82BBD8",
    focus: "#82BBD8",
  },

  

  color1A: {
    main: "#F0FBFF",
    focus: "#F0FBFF",
  },
  color2A: {
    main: "#E4F2F9",
    focus: "#E4F2F9",
  },
  color3A: {
    main: "#CDEBF7",
    focus: "#CDEBF7",
  },
  color4A: {
    main: "#A0CEEA",
    focus: "#A0CEEA",
  },
  color5A: {
    main: "#80B5DD",
    focus: "#80B5DD",
  },
  color6A: {
    main: "#4A80A8",
    focus: "#4A80A8",
  },
  color7A: {
    main: "#2D5375",
    focus: "#2D5375",
  },

  color1V: {
    main: "#EDFCF4",
    focus: "#EDFCF4",
  },
  color2V: {
    main: "#E1F4EA",
    focus: "#E1F4EA",
  },
  color3V: {
    main: "#D6F2E5",
    focus: "#D6F2E5",
  },
  color4V: {
    main: "#AEE5D1",
    focus: "#AEE5D1",
  },
  color5V: {
    main: "#80C1AA",
    focus: "#80C1AA",
  },
  color6V: {
    main: "#5D8D80",
    focus: "#5D8D80",
  },
  color7V: {
    main: "#3A4F48",
    focus: "#3A4F48",
  },

  color1N: {
    main: "#FFF8F0",
    focus: "#FFF8F0",
  },
  color2N: {
    main: "#DEA867",
    focus: "#DEA867",
  },
  color3N: {
    main: "#BD8020",
    focus: "#BD8020",
  },

  color1R: {
    main: "#DB6161",
    focus: "#DB6161",
  },
  color2R: {
    main: "#BD8020",
    focus: "#AF4F4F",
  },

  color1: {
    main: "#1A1A1A",
    focus: "#1A1A1A",
  },
  color2: {
    main: "#333333",
    focus: "#333333",
  },
  color3: {
    main: "#3E3E3E",
    focus: "#3E3E3E",
  },
  color4: {
    main: "#4D4D4D",
    focus: "#4D4D4D",
  },
  color5: {
    main: "#5A5A5A",
    focus: "#5A5A5A",
  },
  color6: {
    main: "#666666",
    focus: "#666666",
  },
  color7: {
    main: "#787878",
    focus: "#787878",
  },
  color8: {
    main: "#808080",
    focus: "#808080",
  },
  color9: {
    main: "#8C8C8C",
    focus: "#8C8C8C",
  },

  color10: {
    main: "#929292",
    focus: "#929292",
  },
  color11: {
    main: "#999999",
    focus: "#999999",
  },
  color12: {
    main: "#B3B3B3",
    focus: "#B3B3B3",
  },
  color13: {
    main: "#BEBEBE",
    focus: "#BEBEBE",
  },
  color14: {
    main: "#E6E6E6",
    focus: "#E6E6E6",
  },
  color15: {
    main: "#EEEEEE",
    focus: "#EEEEEE",
  },
  color16: {
    main: "#F0F0F0",
    focus: "#F0F0F0",
  },
  color17: {
    main: "#F7F7F7",
    focus: "#F7F7F7",
  },
  color18: {
    main: "#FFFFFF",
    focus: "#FFFFFF",
  },

   /* colores castelec end  */

  grey: {
    100: "#f8f9fa",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#7928ca",
      state: "#ff0080",
    },

    secondary: {
      main: "#627594",
      state: "#a8b8d8",
    },

    info: {
      main: "#2152ff",
      state: "#21d4fd",
    },

    success: {
      main: "#17ad37",
      state: "#98ec2d",
    },

    warning: {
      main: "#f53939",
      state: "#fbcf33",
    },

    error: {
      main: "#ea0606",
      state: "#ff667c",
    },

    light: {
      main: "#ced4da",
      state: "#ebeff4",
    },

    dark: {
      main: "#141727",
      state: "#3a416f",
    },
    /* colores castelec  */
    color1T: {
      main: "#59C6EA",
      focus: "#59C6EA",
    },
  
    color2T: {
      main: "#7EC4BC",
      focus: "#7EC4BC",
    },

    color3T: {
      main: "#84C3CE",
      focus: "#84C3CE",
    },

    color4T: {
      main: "#82BBD8",
      focus: "#82BBD8",
    },

    

    color1A: {
      main: "#F0FBFF",
      focus: "#F0FBFF",
    },
    color2A: {
      main: "#E4F2F9",
      focus: "#E4F2F9",
    },
    color3A: {
      main: "#CDEBF7",
      focus: "#CDEBF7",
    },
    color4A: {
      main: "#A0CEEA",
      focus: "#A0CEEA",
    },
    color5A: {
      main: "#80B5DD",
      focus: "#80B5DD",
    },
    color6A: {
      main: "#4A80A8",
      focus: "#4A80A8",
    },
    color7A: {
      main: "#2D5375",
      focus: "#2D5375",
    },

    color1V: {
      main: "#EDFCF4",
      focus: "#EDFCF4",
    },
    color2V: {
      main: "#E1F4EA",
      focus: "#E1F4EA",
    },
    color3V: {
      main: "#D6F2E5",
      focus: "#D6F2E5",
    },
    color4V: {
      main: "#AEE5D1",
      focus: "#AEE5D1",
    },
    color5V: {
      main: "#80C1AA",
      focus: "#80C1AA",
    },
    color6V: {
      main: "#5D8D80",
      focus: "#5D8D80",
    },
    color7V: {
      main: "#3A4F48",
      focus: "#3A4F48",
    },

    color1N: {
      main: "#FFF8F0",
      focus: "#FFF8F0",
    },
    color2N: {
      main: "#DEA867",
      focus: "#DEA867",
    },
    color3N: {
      main: "#BD8020",
      focus: "#BD8020",
    },

    color1R: {
      main: "#DB6161",
      focus: "#DB6161",
    },
    color2R: {
      main: "#BD8020",
      focus: "#AF4F4F",
    },

    color1: {
      main: "#1A1A1A",
      focus: "#1A1A1A",
    },
    color2: {
      main: "#333333",
      focus: "#333333",
    },
    color3: {
      main: "#3E3E3E",
      focus: "#3E3E3E",
    },
    color4: {
      main: "#4D4D4D",
      focus: "#4D4D4D",
    },
    color5: {
      main: "#5A5A5A",
      focus: "#5A5A5A",
    },
    color6: {
      main: "#666666",
      focus: "#666666",
    },
    color7: {
      main: "#787878",
      focus: "#787878",
    },
    color8: {
      main: "#808080",
      focus: "#808080",
    },
    color9: {
      main: "#8C8C8C",
      focus: "#8C8C8C",
    },

    color10: {
      main: "#929292",
      focus: "#929292",
    },
    color11: {
      main: "#999999",
      focus: "#999999",
    },
    color12: {
      main: "#B3B3B3",
      focus: "#B3B3B3",
    },
    color13: {
      main: "#BEBEBE",
      focus: "#BEBEBE",
    },
    color14: {
      main: "#E6E6E6",
      focus: "#E6E6E6",
    },
    color15: {
      main: "#EEEEEE",
      focus: "#EEEEEE",
    },
    color16: {
      main: "#F0F0F0",
      focus: "#F0F0F0",
    },
    color17: {
      main: "#F7F7F7",
      focus: "#F7F7F7",
    },
    color18: {
      main: "#FFFFFF",
      focus: "#FFFFFF",
    },

     /* colores castelec end  */

  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  alertColors: {
    primary: {
      main: "#7928ca",
      state: "#d6006c",
      border: "#efb6e2",
    },

    secondary: {
      main: "#627594",
      state: "#8ca1cb",
      border: "#dadee6",
    },

    info: {
      main: "#2152ff",
      state: "#02c6f3",
      border: "#b9ecf8",
    },

    success: {
      main: "#17ad37",
      state: "#84dc14",
      border: "#daf3b9",
    },

    warning: {
      main: "#f53939",
      state: "#fac60b",
      border: "#fef1c2",
    },

    error: {
      main: "#ea0606",
      state: "#ff3d59",
      border: "#f9b4b4",
    },

    light: {
      main: "#ced4da",
      state: "#d1dae6",
      border: "#f8f9fa",
    },

    dark: {
      main: "#141727",
      state: "#2c3154",
      border: "#c2c8d1",
    },

    /* colores castelec  */
    
    color1T: {
      main: "#59C6EA",
      state: "#59C6EA",
      border: "#59C6EA",
    },
  
    color2T: {
      main: "#7EC4BC",
      state: "#7EC4BC",
      border: "#7EC4BC",
    },
  
    color3T: {
      main: "#84C3CE",
      state: "#84C3CE",
      border: "#84C3CE",
    },
  
    color4T: {
      main: "#82BBD8",
      state: "#82BBD8",
      border: "#82BBD8",
    },
  
    color1A: {
      main: "#F0FBFF",
      state: "#F0FBFF",
      border: "#F0FBFF",
    },
    color2A: {
      main: "#E4F2F9",
      state: "#E4F2F9",
      border: "#E4F2F9",
    },
    color3A: {
      main: "#CDEBF7",
      state: "#CDEBF7",
      border: "#CDEBF7",
    },
    color4A: {
      main: "#A0CEEA",
      state: "#A0CEEA",
      border: "#A0CEEA",
    },
    color5A: {
      main: "#80B5DD",
      state: "#80B5DD",
      border: "#80B5DD",
    },
    color6A: {
      main: "#4A80A8",
      state: "#4A80A8",
      border: "#4A80A8",
    },
    color7A: {
      main: "#2D5375",
      state: "#2D5375",
      border: "#2D5375",
    },
  
    color1V: {
      main: "#EDFCF4",
      state: "#EDFCF4",
      border: "#EDFCF4",
    },
    color2V: {
      main: "#E1F4EA",
      state: "#E1F4EA",
      border: "#E1F4EA",
    },
    color3V: {
      main: "#D6F2E5",
      state: "#D6F2E5",
      border: "#D6F2E5",
    },
    color4V: {
      main: "#AEE5D1",
      state: "#AEE5D1",
      border: "#AEE5D1",
    },
    color5V: {
      main: "#FFF8F0",
      state: "#FFF8F0",
      border: "#FFF8F0",
    },
    color6V: {
      main: "#5D8D80",
      state: "#5D8D80",
      border: "#5D8D80",
    },
    color7V: {
      main: "#3A4F48",
      state: "#3A4F48",
      border: "#3A4F48",
    },
  
    color1N: {
      main: "#FFF8F0",
      state: "#FFF8F0",
      border: "#FFF8F0",
    },
    color2N: {
      main: "#DEA867",
      state: "#DEA867",
      border: "#DEA867",
    },
    color3N: {
      main: "#BD8020",
      state: "#BD8020",
      border: "#BD8020",
    },
  
    color1R: {
      main: "#DB6161",
      state: "#DB6161",
      border: "#DB6161",
    },
    color2R: {
      main: "#BD8020",
      state: "#AF4F4F",
      border: "#AF4F4F",
    },
  
    color1: {
      main: "#1A1A1A",
      state: "#1A1A1A",
      border: "#1A1A1A",
    },
    color2: {
      main: "#333333",
      state: "#333333",
      border: "#333333",
    },
    color3: {
      main: "#3E3E3E",
      state: "#3E3E3E",
      border: "#3E3E3E",
    },
    color4: {
      main: "#4D4D4D",
      state: "#4D4D4D",
      border: "#4D4D4D",
    },
    color5: {
      main: "#5A5A5A",
      state: "#5A5A5A",
      border: "#5A5A5A",
    },
    color6: {
      main: "#666666",
      state: "#666666",
      border: "#666666",
    },
    color7: {
      main: "#787878",
      state: "#787878",
      border: "#787878",
    },
    color8: {
      main: "#808080",
      state: "#808080",
      border: "#808080",
    },
    color9: {
      main: "#8C8C8C",
      state: "#8C8C8C",
      border: "#8C8C8C",
    },
  
    color10: {
      main: "#929292",
      state: "#929292",
      border: "#929292",
    },
    color11: {
      main: "#999999",
      state: "#999999",
      border: "#999999",
    },
    color12: {
      main: "#B3B3B3",
      state: "#B3B3B3",
      border: "#B3B3B3",
    },
    color13: {
      main: "#BEBEBE",
      state: "#BEBEBE",
      border: "#BEBEBE",
    },
    color14: {
      main: "#E6E6E6",
      state: "#E6E6E6",
      border: "#E6E6E6",
    },
    color15: {
      main: "#EEEEEE",
      state: "#EEEEEE",
      border: "#EEEEEE",
    },
    color16: {
      main: "#F0F0F0",
      state: "#F0F0F0",
      border: "#F0F0F0",
    },
    color17: {
      main: "#F7F7F7",
      state: "#F7F7F7",
      border: "#F7F7F7",
    },
    color18: {
      main: "#FFFFFF",
      state: "#FFFFFF",
      border: "#FFFFFF",
    },

     /* colores castelec end  */

  },

  badgeColors: {
    primary: {
      background: "#f883dd",
      text: "#a3017e",
    },

    secondary: {
      background: "#e4e8ed",
      text: "#5974a2",
    },

    info: {
      background: "#abe9f7",
      text: "#08a1c4",
    },

    success: {
      background: "#cdf59b",
      text: "#67b108",
    },

    warning: {
      background: "#fef5d3",
      text: "#fbc400",
    },

    error: {
      background: "#fc9797",
      text: "#bd0000",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
    
    /* castelec start  */
    color1T: {
      background: "#59C6EA",
      text: "#F3F3F3",
    },
  
    color2T: {
      background: "#7EC4BC",
      text: "#F3F3F3",
    },
  
    color3T: {
      background: "#84C3CE",
      text: "#F3F3F3",
    },
  
    color4T: {
      background: "#82BBD8",
      text: "#F3F3F3",
    },
  
    color1A: {
      background: "#F0FBFF",
      text: "#F3F3F3",
    },
    color2A: {
      background: "#E4F2F9",
      text: "#F3F3F3",
    },
    color3A: {
      background: "#CDEBF7",
      text: "#F3F3F3",
    },
    color4A: {
      background: "#A0CEEA",
      text: "#F3F3F3",
    },
    color5A: {
      background: "#80B5DD",
      text: "#F3F3F3",
    },
    color6A: {
      background: "#4A80A8",
      text: "#F3F3F3",
    },
    color7A: {
      background: "#2D5375",
      text: "#F3F3F3",
    },
  
    color1V: {
      background: "#EDFCF4",
      text: "#F3F3F3",
    },
    color2V: {
      background: "#E1F4EA",
      text: "#F3F3F3",
    },
    color3V: {
      background: "#D6F2E5",
      text: "#F3F3F3",
    },
    color4V: {
      background: "#AEE5D1",
      text: "#F3F3F3",
    },
    color5V: {
      background: "#80C1AA",
      text: "#F3F3F3",
    },
    color6V: {
      background: "#5D8D80",
      text: "#F3F3F3",
    },
    color7V: {
      background: "#3A4F48",
      text: "#F3F3F3",
    },
  
    color1N: {
      background: "#FFF8F0",
      text: "#F3F3F3",
    },
    color2N: {
      background: "#DEA867",
      text: "#F3F3F3",
    },
    color3N: {
      background: "#BD8020",
      text: "#F3F3F3",
    },
  
    color1R: {
      background: "#DB6161",
      text: "#F3F3F3",
    },
    color2R: {
      background: "#BD8020",
      text: "#F3F3F3",
    },
  
    color1: {
      background: "#1A1A1A",
      text: "#F3F3F3",
    },
    color2: {
      background: "#333333",
      text: "#F3F3F3",
    },
    color3: {
      background: "#3E3E3E",
      text: "#F3F3F3",
    },
    color4: {
      background: "#4D4D4D",
      text: "#F3F3F3",
    },
    color5: {
      background: "#5A5A5A",
      text: "#F3F3F3",
    },
    color6: {
      background: "#666666",
      text: "#F3F3F3",
    },
    color7: {
      background: "#787878",
      text: "#F3F3F3",
    },
    color8: {
      background: "#808080",
      text: "#F3F3F3",
    },
    color9: {
      background: "#8C8C8C",
      text: "#F3F3F3",
    },
  
    color10: {
      background: "#929292",
      text: "#F3F3F3",
    },
    color11: {
      background: "#999999",
      text: "#F3F3F3",
    },
    color12: {
      background: "#B3B3B3",
      text: "#F3F3F3",
    },
    color13: {
      background: "#BEBEBE",
      text: "#F3F3F3",
    },
    color14: {
      background: "#E6E6E6",
      text: "#F3F3F3",
    },
    color15: {
      background: "#EEEEEE",
      text: "#F3F3F3",
    },
    color16: {
      background: "#F0F0F0",
      text: "#F3F3F3",
    },
    color17: {
      background: "#F7F7F7",
      text: "#F3F3F3",
    },
    color18: {
      background: "#FFFFFF",
      text: "#F3F3F3",
    },
    /*Castelec end */
  },

  inputColors: {
    borderColor: { main: "#d2d6da", focus: "#35d1f5" },
    boxShadow: "#81e3f9",
    error: "#fd5c70",
    success: "#66d432",
    castelec : "#E1601F"
  },

  sliderColors: {
    thumb: { borderColor: "#d9d9d9" },
  },

  circleSliderColors: {
    background: "#d3d3d3",
  },

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
