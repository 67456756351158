import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import {useDispatch, useSelector} from "react-redux";
import LoaderAlpha from "../../dashboard/components/LoaderAlpha";
import Grid from "@mui/material/Grid";
import * as React from "react";
import Servidores from "../../../Repository/Banca/Servidores";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";
import {usePasswordGenerator} from "../../../customHooks/usePasswordGenerator";
import Admin from "../../../Repository/Banca/Admin";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";
const Usuario = ({ server = {}, userInfo = {} }) => {

    const dispatch = useDispatch();
    const { setOpenAlert,alphaAlert,setMessageAlert,openAlert } = useAlphaAlertModal();
    const [correo, setCorreo] = useState(userInfo.correo);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [nombreCompleto, setNombreCompleto] = useState(userInfo.nombreCompleto);
    const [deleted, setDeleted] = useState(userInfo.deleted !== null);
    const { generatePassword } = usePasswordGenerator();
    const adminx = new Admin();
    const onClickGuardar = async () => {
        const data = {
            correo:correo.trim(),
            nombreCompleto:nombreCompleto.trim(),
        }

        if(password.trim() !== '')
        {
            data.password = password.trim();
        }

        dispatch( openGeneralLoader )
        const { status, response } = await adminx.update(data, userInfo.uuid);
        setMessageAlert(response);
        setOpenAlert(true);
        dispatch( closeGeneralLoader );
    }

    const onClickChangeStatus = async() => {
        const data = {
            ...userInfo,
            deleted:true,
        }

        dispatch( openGeneralLoader )
        const { status, response } = await adminx.update(data, userInfo.uuid);
        setMessageAlert(response);
        setOpenAlert(true);
        dispatch( closeGeneralLoader );
        if (status === 'ok')
        {
            setDeleted( !deleted );
        }
    }

    const onClickGenerarContrasenia = async () =>{
        const pwdc = generatePassword( )
        setPassword( pwdc );
    }

    const onclickTogglePassword = () => {
        setShowPassword( !showPassword );
    }

    const onclickCopy = () => {
        navigator.clipboard.writeText(password);
    }

    return (
        <>
            <SoftBox bgColor={"white"} mt={2} sx={{border: "5px solid #E6E6E6", paddingLeft: 1, paddingRight: 1, paddingTop:1}}>
                <div className="accordion-header"  >
                    <i className={" icons-alpha"}></i> <label
                    className={"filtro-text"}>{"Acceso del cliente"}</label>
                </div>
                <Grid container pt={1}>
                    <Grid item xs={12} md={2} xl={2} pl={1}>
                        <span className={"lbl-etiqueta"}>Nombre Completo </span>
                    </Grid>
                    <Grid item xs={12} md={3} xl={3}>
                        <input type={"text"} className={"ITextbox"} name={"nombreCompleto"} value={ nombreCompleto } maxLength={120}
                               onChange={ (event) => setNombreCompleto(event.target.value) }
                        />
                    </Grid>
                    <Grid item xs={12} md={2} xl={2} pl={1}>
                        <span className={"lbl-etiqueta"}>Correo</span>
                    </Grid>
                    <Grid item xs={12} md={3} xl={3}>
                        <input type={"text"} className={"ITextbox"} name={"correo"} value={ correo } maxLength={120}
                               onChange={ (event) => setCorreo(event.target.value) }
                        />
                    </Grid>
                </Grid>
                <Grid container pt={1}>
                    <Grid item xs={12} md={2} xl={2} pl={1}>
                        <span className={"lbl-etiqueta"}>Password</span>
                    </Grid>
                    <Grid item xs={8} md={3} xl={3}>
                        <input type={showPassword ? "text":"password"} className={"ITextbox"} name={"password"} value={ password } maxLength={20}
                               onChange={ (event) => setPassword(event.target.value) }
                        />
                    </Grid>
                    <Grid item xs={2} md={1} xl={1} mt={2} display={"flex"}>
                        <button className={"miniBtn"} type={"submit"} onClick={onClickGenerarContrasenia}>
                            <i className="fa-solid fa-key"></i>
                        </button>
                        <button className={"miniBtn"} type={"submit"} onClick={onclickTogglePassword}>
                            <i className={showPassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"}></i>
                        </button>
                        <button className={"miniBtn"} type={"submit"} onClick={onclickCopy}>
                            <i className={"fa-regular fa-copy"}></i>
                        </button>
                    </Grid>
                </Grid>

                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={12} md={3} xl={3} mt={2} mr={ 1}>
                        <button className={ deleted ? "btn-alpha-info-3":"btn-alpha-gray-1"} type={"submit"} onClick={onClickChangeStatus}>
                            <i className="fa-regular fa-floppy-disk"></i> { deleted ? 'Activar':'Desactivar' } Acceso
                        </button>
                    </Grid>
                    <Grid item xs={12} md={2} xl={2} mt={2}>
                        <button className={"btn-alpha-success-2"} type={"submit"} onClick={onClickGuardar}>
                            <i className="fa-regular fa-floppy-disk"></i> Actualizar
                        </button>
                    </Grid>
                </Grid>
            </SoftBox>
            {
                openAlert && alphaAlert
            }
            <LoaderAlpha/>
        </>
    );
}
Usuario.propTypes = {
    server: PropTypes.object,
    userInfo: PropTypes.object,
};
export default Usuario;