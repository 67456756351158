import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {logout} from "../store/slice/authSlice";

export const useServiceWorker = (swPath = '') => {
    const [errorCode, setErrorCode] = useState('');
    const [sWorker, setSWorker] = useState();
    const dispatch = useDispatch();

    useEffect(()=>{
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register(swPath,{ updateViaCache:'none' })
                .then((registration) => {
                    let serviceWorker;
                    if (registration.installing) {
                        serviceWorker = registration.installing;
                        console.log("installing") ;
                    } else if (registration.waiting) {
                        serviceWorker = registration.waiting;
                        console.log("waiting") ;
                    } else if (registration.active) {
                        serviceWorker = registration.active;
                        console.log("active");
                    }
                    setSWorker( serviceWorker );
                })
                .catch((error) => {
                    // Something went wrong during registration. The service-worker.js file
                    // might be unavailable or contain a syntax error.
                    setErrorCode(error)
                });
        } else {
            // The current browser doesn't support service workers.
            // Perhaps it is too old or we are not in a Secure Context.
        }
    },[])

    navigator.serviceWorker.addEventListener("message", (event) => {
        const { action, payload } = event.data;
        switch (action){
            case 'logoutSession':
                dispatch ( logout() );
                break;
            case 'refreshSession':
                const dataSw = event.data.payload;
                const enLocal = JSON.parse(localStorage.getItem("session")) ;
                enLocal.token = dataSw.token;
                enLocal.expires = dataSw.expires;
                localStorage.setItem('session', JSON.stringify(enLocal));
                localStorage.setItem("cToken", dataSw.token);
                break;
        }
    });

    useEffect(()=>{
        navigator.serviceWorker.ready.then((sw) => {
            const checkToken =setInterval(()=>{
                sw.active.postMessage({action:"verifySession",payload:{}});
            }, 60000);
            sw.active.postMessage({action:"verifySession",payload:{}});

            setInterval(()=>{
                sw.active.postMessage({action:"preventStopped",payload:{}});
            }, 10000);

        });
    },[])

    useEffect(()=>{
        navigator.serviceWorker.ready.then((sw) => {
            sw.active.postMessage({action:"serverPath",payload: process.env.REACT_APP_API_CORE  });
        });
    },[])

    return {
        errorCode,
        sWorker
    }

}