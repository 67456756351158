/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Listado from "../servidores/components/Listado";
import Instancias from "../servidores/components/Instancias";

function Dashboard() {

    const dispatch = useDispatch();
    const { windowHeight } = useSelector( state => state.ventana );
    const {  roles = [], user  } = useSelector(state => state.auth);

    return (
      <DashboardLayout sx={{ overflow:"hidden" }}>
        <DashboardNavbar light={true} />
        <SoftBox >
            {
                <>
                { roles.includes('CUSTOMER') && <Instancias /> }
                { roles.includes('ADMIN') && <Listado />  }
                </>
            }
        </SoftBox>
      </DashboardLayout>
    );
  
}

export default Dashboard;
