import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Instancia from "../../servidores/components/Instancia";

import logox from "../../../assets/alpha-theme/images/alphaerpnube.svg"
import {useMediaQuery} from "@mui/material";

const LogoComponent = ({ fSize = "1.6rem", widthLogo="auto" })=>{
    const isMobile = useMediaQuery('(max-width:768px)');
    const isIpad = useMediaQuery('(min-width : 769px) and (max-width : 992px)');
    return <>
        <Box sx={{ textAlign:"center",width:"100%" }}>
            <img src={ logox } width={isMobile?250:( isIpad ? 250: 350) }  alt={"Logo Castelec Cloud"} />
        </Box>
    </>
}

LogoComponent.propTypes = {
    fSize: PropTypes.string,
    widthLogo: PropTypes.string,
};
export default LogoComponent;