/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Billing page components

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "context";
import { useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/slice/authSlice";


function Configurator() {

  const dispatchRedux = useDispatch();
  const { logged, user } = useSelector(state => state.auth);
  
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, transparentSidenav, fixedNavbar, sidenavColor } = controller;
  const [disabled, setDisabled] = useState(false);

  const  [nombres, setNombres] =  useState('');
  const [uuidSesion, setUuidSesion] =  useState('');
  const [correo, setCorreo] = useState('') ;
  const navigatex = useNavigate();

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  useEffect(()=>{
    setNombres( user?.nombre.toUpperCase() );
    setUuidSesion(user?.uuid);
  },[user])

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => setTransparentSidenav(dispatch, true);
  const handleWhiteSidenav = () => setTransparentSidenav(dispatch, false);
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const navigate = useNavigate();

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    boxShadows: { buttonBoxShadow },
  }) => ({
    height: pxToRem(42),
    boxShadow: buttonBoxShadow.main,

    "&:hover, &:focus": {
      opacity: 1,
    },
  });

  //terminar la sesion del usuario
  function closeSession(){
    localStorage.clear();
    dispatchRedux( logout() );
  }

  const handle2FaActivated = (event)=>{
    setSend2Fa(true);
  }

  const gotoConfiguracion= ()=>{
    setOpenConfigurator(dispatch, false );
    navigatex('/configuracion');
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }} >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox color="text">
          <SoftTypography variant="h5" color="text" >{ user ? user.nombre.toUpperCase(): ""  }</SoftTypography>
        </SoftBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SoftBox>



      <SoftBox pt={1.25} pb={3} px={3}>

          { nombres && 
          <SoftBox mt={3} lineHeight={1}>
            <SoftBox
                sx={{
                  display: "flex",
                  mt: 2,
                }}
              >
               
                <SoftButton
                  color="error"
                  variant={transparentSidenav ? "outlined" : "gradient"}
                  onClick={closeSession}
                  disabled={disabled}
                  fullWidth
                  sx={sidenavTypeButtonsStyles}
                >
                  Finalizar sesión
                </SoftButton>
              </SoftBox>
            </SoftBox>
          }

           

          

       

        <Divider />

        
      </SoftBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
