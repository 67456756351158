
/**
 * {"zona": ["614f71d6-098d-4e5a-88e8-41ba45cc2b85"],
 * "perfil": "ADMIN", "archivar": true, "servidor": [], "desarchivar": true, "actualizaSucursal": true, "actualizaListadoServidores": true}
 * Clase para recibir los permisos en el cliente, debe llenarse con lo que nos manda el servidor
 */
class Permiso
{
    constructor(permisos = {}) {
        this.perfil = permisos.actions?.perfil;
        this.archivar = permisos.actions?.archivar;
        this.desarchivar = permisos.actions?.desarchivar;
        this.actualizaSucursal = permisos.actions?.actualizaSucursal;
        this.actualizaListadoServidores = permisos.actions?.actualizaListadoServidores;
        this.encender = permisos.actions?.encender;
        this.reiniciar = permisos.actions?.reiniciar;
        this.reinicioForzado = permisos.actions?.reinicioForzado;
        this.consolaEmergencia = permisos.actions?.consolaEmergencia;
        this.exportaListadoServidores = permisos.actions?.exportaListadoServidores
    }
}

export default Permiso;