import { useState} from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import * as React from "react";
import {useAlphaAlertModal} from "../../../customHooks/useAlphaAlertModal";
import {usePasswordGenerator} from "../../../customHooks/usePasswordGenerator";
import Admin from "../../../Repository/Banca/Admin";
import {closeGeneralLoader, openGeneralLoader} from "../../../store/slice/alertSlice";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../components/SoftBox";
import LoaderAlpha from "../../dashboard/components/LoaderAlpha";
const NewUsuario = ({ server = {}, handleUserCreated = () => { /* not implemented*/ }  }) => {

    const dispatch = useDispatch();
    const { setOpenAlert,alphaAlert,setMessageAlert,openAlert } = useAlphaAlertModal();
    const [correo, setCorreo] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [nombreCompleto, setNombreCompleto] = useState('');
    const { generatePassword } = usePasswordGenerator();

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const iconDown = <i className="fa-solid fa-chevron-down icons-alpha"></i>;
    const iconUp = <i className="fa-solid fa-angle-up icons-alpha"></i>;

    const onClickGuardar = async () => {

        const data = {
            password:password.trim(),
            correo:correo.trim(),
            nombreCompleto:nombreCompleto.trim(),
            server_uuid: server.uuid,
        }

        dispatch( openGeneralLoader )
        const { status, response } = await Admin.store(data);
        setMessageAlert(response);
        setOpenAlert(true);
        dispatch( closeGeneralLoader );
        setCorreo('');
        setPassword('');
        setNombreCompleto('');
        handleUserCreated();
    }

    const onClickGenerarContrasenia = async () =>{
        const pwdc = generatePassword( )
        setPassword( pwdc );
    }

    const onclickTogglePassword = () => {
        setShowPassword( !showPassword );
    }

    const onclickCopy = () => {
        navigator.clipboard.writeText(password);
    }

    return (
        <>
                <SoftBox bgColor={"white"} mt={2}>
                    <div className="accordion-header" onClick={handleToggle}>
                        <i className={" icons-alpha"}></i> <label
                        className={"filtro-text"}>{"Generar acceso"}</label>
                        <label className={"icon-colapse-position"}>
                            {isCollapsed ? iconUp : iconDown}
                        </label>
                    </div>
                    {isCollapsed && (
                        <div className="accordion-body">
                            <SoftBox bgColor={"white"} mt={0.5}
                                     sx={{border: "5px solid #E6E6E6", paddingLeft: 1, paddingRight: 1, paddingTop: 1}}>
                                <Grid container pt={1}>
                                    <Grid item xs={12} md={2} xl={2} pl={1}>
                                        <span className={"lbl-etiqueta"}>Nombre Completo </span>
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                        <input type={"text"} className={"ITextbox"} name={"nombreCompleto"}
                                               value={nombreCompleto} maxLength={120}
                                               onChange={(event) => setNombreCompleto(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} xl={2} pl={1}>
                                        <span className={"lbl-etiqueta"}>Correo</span>
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                        <input type={"text"} className={"ITextbox"} name={"correo"} value={correo}
                                               maxLength={120}
                                               onChange={(event) => setCorreo(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container pt={1}>
                                    <Grid item xs={12} md={2} xl={2} pl={1}>
                                        <span className={"lbl-etiqueta"}>Password</span>
                                    </Grid>
                                    <Grid item xs={8} md={3} xl={3}>
                                        <input type={showPassword ? "text" : "password"} className={"ITextbox"}
                                               name={"password"} value={password} maxLength={20}
                                               onChange={(event) => setPassword(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={1} xl={1} mt={2} display={"flex"}>
                                        <button className={"miniBtn"} type={"submit"}
                                                onClick={onClickGenerarContrasenia}>
                                            <i className="fa-solid fa-key"></i>
                                        </button>
                                        <button className={"miniBtn"} type={"submit"} onClick={onclickTogglePassword}>
                                            <i className={showPassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"}></i>
                                        </button>
                                        <button className={"miniBtn"} type={"submit"} onClick={onclickCopy}>
                                            <i className={"fa-regular fa-copy"}></i>
                                        </button>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent={"flex-end"}>
                                    <Grid item xs={12} md={2} xl={2} mt={2}>
                                        <button className={"btn-alpha-success-2"} type={"submit"}
                                                onClick={onClickGuardar}>
                                            <i className="fa-regular fa-floppy-disk"></i> Guardar
                                        </button>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </div>
                    )}
                </SoftBox>
            {
                openAlert && alphaAlert
            }
            <LoaderAlpha/>
        </>
    );
}
NewUsuario.propTypes = {
    server: PropTypes.object,
    handleUserCreated: PropTypes.func,
};
export default NewUsuario;