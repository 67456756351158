import Operacion from './Operacion';

class Sucursal extends Operacion {

    constructor() {
        super();
    }

    /**
     * Descarga un listado de sucursales
     * @returns {Promise<void>}
     */
    async listado () {
        const url = `panel/sucursales`;
        return await  this.transaction('GET',url, null , true );
    }

}

export default Sucursal;